import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  :root{
    --singleColSize: calc(100vw / 12);
    --max-width: 1440px;
  }


    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        border: 0;
        font-size: 100%;
        margin: 0;
        padding: 0;
        vertical-align: baseline;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    picture {
        display: block;
    }

    body {
        line-height: 1;
        overscroll-behavior-y: none;
    }

    ol,
    ul {
        list-style: none;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
        content: '';
        content: none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        -webkit-tap-highlight-color: transparent;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    // Basic Styles
    html {
        font-weight: 400;
        font-family: ${({ theme }) => theme.fonts.main};
        color: ${({ theme }) => theme.colors.general.light()};
        box-sizing: border-box;
        width: 100%;
        overflow-x: hidden;
        font-size: 16px;

        *, *:before, *:after {
            box-sizing: border-box;
        }
    }

    mark{
      color: ${({ theme }) => theme.colors.general.light()};
    }

    body {
        display: block !important;
        width: 100%;
        overflow-x: hidden;
    }

    html,
    body {
      min-height: 100%;
      height: auto;
      overscroll-behavior: none;

    }
    html.lenis {
      height: auto;
    }

    .lenis.lenis-smooth {
      scroll-behavior: auto;
    }

    .lenis.lenis-smooth [data-lenis-prevent] {
      overscroll-behavior: contain;
    }

    .lenis.lenis-stopped {
      overflow: hidden;
    }

    .lenis.lenis-scrolling iframe {
      pointer-events: none;
    }

`
export default GlobalStyles
