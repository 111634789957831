export default {
  heading1: {
    'font-size': 40,
    'line-height': '120%',
    'font-weight': 600,
    mq: { lg: { 'font-size': 56, }, },
  },
  heading2: {
    'font-size': 32,
    'line-height': '130%',
    'font-weight': 600,
    mq: { lg: { 'font-size': 48, }, },
  },
  heading3: {
    'font-size': 24,
    'font-weight': 600,
    'line-height': '130%',
    mq: { lg: { 'font-size': 32, }, },
  },
  heading4: {
    'font-size': 20,
    'line-height': '130%',
    'font-weight': 600,
    mq: { lg: { 'font-size': 24, }, },
  },
  heading5: {
    'font-size': 18,
    'line-height': '150%',
    'font-weight': 600,
  },
  heading6: {
    'font-size': 16,
    'line-height': '150%',
    'font-weight': 600,
  },
  paragraphLarge: {
    'font-size': 18,
    'line-height': '170%',
  },
  paragraphRegular: {
    'font-size': 16,
    'line-height': '170%',
  },
  paragraphSmall: {
    'font-size': 14,
    'line-height': '170%',
  },
  paragraphTiny: {
    'font-size': 12,
    'line-height': '170%',
  },
  link1: {
    'font-size': 18,
    'line-height': '150%',
  },
  link2: {
    'font-size': 16,
    'line-height': '150%',
  },
  link3: {
    'font-size': 14,
    'line-height': '150%',
  },
  buttonBig: { 'font-size': 18, },
  buttonRegular: { 'font-size': 16, },
  buttonSmall: { 'font-size': 14, },
  buttonTiny: { 'font-size': 12, },
  inputPlaceholder: {
    'font-size': 16,
    'line-height': 19.7,
  },
  labelBig: { 'font-size': 16, },
  labelSmall: { 'font-size': 14, },
}
