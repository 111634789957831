import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import {
  theme,
  GlobalStyles,
  helpers,
  FontStyles
} from 'theme'

import {
  parmalat,
  chef,
  zymil,
  santal,
} from 'theme/colors'

const colorsThemeMap = {
  parmalat,
  chef,
  zymil,
  santal,
}

const getThemeColors = (themeID) => !!colorsThemeMap[themeID] ? colorsThemeMap[themeID] : colorsThemeMap.parmalat


export const WithSelectedTheme = ({
  theme: localTheme,
  children,
  ...props
}) => {
  const overrodeTheme = useMemo(()=> {
    return getThemeColors(localTheme)
  }, [localTheme])

  return (
    <ThemeProvider theme={{
      ...theme,
      colors: overrodeTheme
    }}>
      {React.cloneElement(children, props)}
    </ThemeProvider>
  )
}

export default ({
  children,
  brand
}) => {
  const colors = getThemeColors(brand)
  return (
    <ThemeProvider theme={{
      ...theme,
      colors,
      ...helpers
    }}>
      <FontStyles />
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}
