import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-creative'
import 'swiper/css/effect-fade'


import ThemeProvider from 'contexts/Theme'
import ResponsiveProvider from 'contexts/Responsive'
import GeneralProvider from 'contexts/General'
import GTMProvider from 'contexts/GTM'

function MyApp({
  Component,
  pageProps,
  // router
}) {
  return (
    <GeneralProvider
      brand={pageProps.brand}
      options={pageProps.options}
      page={pageProps.additionalData}
      menus={pageProps.menus}
    >
      <GTMProvider>
        <ThemeProvider brand={pageProps.brand}>
          <ResponsiveProvider>
            <Component {...pageProps} />
          </ResponsiveProvider>
        </ThemeProvider>
      </GTMProvider>
    </GeneralProvider>
  )
}

export default MyApp
