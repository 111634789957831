import { createGlobalStyle } from 'styled-components'

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Signika';
    src: url('/fonts/Signika/Signika-Light.woff2') format('woff2'),
          url('/fonts/Signika/Signika-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Signika';
    src: url('/fonts/Signika/Signika-SemiBold.woff2') format('woff2'),
          url('/fonts/Signika/Signika-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

`

export default FontStyles
