const common = {
  general: {
    dark: (alpha = 1) => `rgba(16, 41, 74, ${alpha})`,
    medium: (alpha = 1) => `rgba(20, 51, 93, ${alpha})`,
    light: (alpha = 1) => `rgba(25, 64, 116, ${alpha})`,
  },
  status: {
    success: { default: 'rgba(93, 154, 92, 1)', },
    warning: { default: 'rgba(222, 163, 42, 1)', },
    error: { default: 'rgba(219, 80, 74, 1)', },
  },
  grayscale: {
    white: 'rgba(255, 255, 255, 1)',
    gray100: 'rgba(231, 234, 237, 1)',
    gray200: 'rgba(207, 212, 219, 1)',
    gray300: 'rgba(183, 191, 201, 1)',
    gray400: 'rgba(159, 169, 183, 1)',
    gray500: 'rgba(159, 169, 183, 1)',
    gray600: 'rgba(112, 127, 146, 1)',
    gray700: 'rgba(88, 105, 128, 1)',
    gray800: 'rgba(64, 84, 110, 1)',
    black: 'rgba(0, 0, 0, 1)',
  },
}


export const parmalat = {
  accent: (alpha = 1) => `rgba(152, 209, 241, ${alpha})`,
  medium: (alpha = 1) => `rgba(140, 192, 222, ${alpha})`,
  background: (alpha = 1) => `rgba(250, 247, 237, ${alpha})`,
  ...common,
}

export const chef = {
  accent: (alpha = 1) => `rgba(253, 201, 1, ${alpha})`,
  medium: (alpha = 1) => `rgba(235, 187, 1, ${alpha})`,
  background: (alpha = 1) => `rgba(250, 247, 237, ${alpha})`,
  ...common,
}

export const zymil = {
  accent: (alpha = 1) => `rgba(255, 161, 106, ${alpha})`,
  medium: (alpha = 1) => `rgba(236, 154, 106, ${alpha})`,
  background: (alpha = 1) => `rgba(250, 243, 237, ${alpha})`,
  ...common,
}

export const santal = {
  accent: (alpha = 1) => `rgba(150, 211, 126, ${alpha})`,
  medium: (alpha = 1) => `rgba(138, 194, 116, ${alpha})`,
  background: (alpha = 1) => `rgba(237, 244, 237, ${alpha})`,
  ...common,
}


