import typography from './typography'
import breakpoints from './breakpoints'
import { parmalat as colors } from './colors'
export default {
  // customGridConf: {},
  siteColumns: 12,
  ease: 'ease',
  timing: {
    fast: '0.2s',
    normal: '0.5s',
    slow: '1s',
    verySlow: '5s',
  },
  margins: {
    default: '3.2rem',
    large: '5.2rem',
  },
  defaults: { size: 8, },
  colors,
  shadows: {
    low: (y = 0) => `0px ${y}px 10px rgba(0, 0, 0, 0.15)`,
    medium: '5px 20px 90px rgba(0, 0, 0, 0.15)',
    compromise: (size = '4vw') => `0px 0px ${size} rgba(0,0,0,0.25)`,
    high: '0px 0px 70px rgba(0, 0, 0, 0.25);',
    product: '0px 20px 90px rgba(0, 0, 0, 0.3)',
    hover: '0 20px 32px -14px rgba(0,0,0,0.35)',
  },
  fonts: { main: '\'Signika\', sans-serif' },
  typography,
  layers: { modal: 20, },
  breakpoints,
}
